import { singleton, inject } from "tsyringe";

// domain
import {
    AcademyRepository,
    RequestGetAcademyCandidatesStudentsPayload,
    RequestGetAcademyCandidatesStudentPayload,
    RequestPostAcademyCandidatesStudentPayload,
    RequestPostAcademyCandidatesStudentInvitationsPayload,
    RequestPatchAcademyCandidatesStudentPayload,
    RequestPatchAcademyClassroomPayload,
    RequestDeleteAcademyCandidatesStudentPayload,
} from "@/domain/academy/repositories/repositorie";
import type {
    GetAcademyCandidatesStudentsDto,
    AcademyCandidateDto,
    AcademyDto,
} from "@/domain/academy/dtos";
// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class AcademyRepositoryImpl implements AcademyRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /** @description 비재원생 학생 정보를 불러옵니다. */
    public getAcademyCandidatesStudent = async (
        payload: RequestGetAcademyCandidatesStudentPayload,
    ) => {
        const response = await this.server.get<AcademyCandidateDto>(
            `/v1/academies/${payload.academyId}/academy-candidates/${payload.candidateStudentId}`,
        );

        return response;
    };

    /** @description 비재원생 학생들의 정보를 불러옵니다. */
    public getAcademyCandidatesStudents = async (
        payload: RequestGetAcademyCandidatesStudentsPayload,
    ) => {
        let url = `/v1/academies/${payload.academyId}/academy-candidates?`;

        if (payload.keyword) {
            url += `keyword=${payload.keyword}`;
        }

        if (payload.size) {
            url += `size=${payload.size}`;
        } else {
            url += `size=10`;
        }

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.server.get<GetAcademyCandidatesStudentsDto>(url);

        return response;
    };

    /** @description 비재원생 정보 신규 생성 */
    public postAcademyCandidatesStudent = async (
        payload: RequestPostAcademyCandidatesStudentPayload,
    ) => {
        const response = await this.server.post<AcademyCandidateDto>(
            `/v1/academies/${payload.academyId}/academy-candidates`,
            payload.requestDto,
        );

        return response;
    };

    /** @description 특정 비재원생을 대상으로 초대 SMS 를 보냅니다. */
    public postAcademyCandidatesStudentInvitations = async (
        payload: RequestPostAcademyCandidatesStudentInvitationsPayload,
    ) => {
        await this.server.post(
            `/v1/academies/${payload.academyId}/academy-candidates/${payload.requestDto.academyCandidateId}/invitations`,
            payload.requestDto,
        );
    };

    /** @description 비재원생의 가입 정보를 patch 합니다. */
    public patchAcademyCandidatesStudent = async (
        payload: RequestPatchAcademyCandidatesStudentPayload,
    ) => {
        const response = await this.server.patch<AcademyCandidateDto>(
            `/v1/academies/${payload.academyId}/academy-candidates/${payload.candidateStudentId}`,
            payload.requestDto,
        );

        return response;
    };

    /**
     * @description 좌석을 변경합니다.
     *  */
    public patchAcademyClassroom = async (payload: RequestPatchAcademyClassroomPayload) => {
        const response = await this.server.patch<AcademyDto>(
            `/v1/academies/${payload.academyId}`,
            payload.requestDto,
        );

        return response;
    };

    /** @description 비재원생을 삭제합니다. */
    public deleteAcademyCandidatesStudent = async (
        payload: RequestDeleteAcademyCandidatesStudentPayload,
    ) => {
        await this.server.delete(
            `/v1/academies/${payload.academyId}/academy-candidates/${payload.candidateId}`,
        );
    };
}
