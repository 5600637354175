import { singleton, inject } from "tsyringe";

// domain
import {
    CurriculumRepository,
    RequestGetPlannedCurriculumPreviewPayload,
} from "@/domain/curriculum/repositories/repositories";
import type {
    GetCurriculumsResponseDto,
    GetPlannedCurriculumsResponseDto,
    GetMiddleUnitsResponseDto,
} from "@/domain/curriculum/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http/index";

@singleton()
export default class CurriculumRepositoryImpl implements CurriculumRepository {
    constructor(
        @inject(HttpAdaptor)
        private readonly httpAdaptor: HttpAdaptor,
    ) {}

    /**
     * @description 커리큘럼 전체 로드맵을 가져옵니다.
     */
    public getCurriculums = async () => {
        const response = await this.httpAdaptor.get<GetCurriculumsResponseDto>(`/v1/curriculums`);

        return response;
    };

    /**
     * @description 설정한 커리큘럼 미리보기를 가져옵니다.
     */
    public getPlannedCurriculumPreview = async (
        payload: RequestGetPlannedCurriculumPreviewPayload,
    ) => {
        let url = `/v1/students/${payload.studentId}/planned-curriculums?`;

        payload.middleUnitId.forEach((middleUnitId) => {
            url += `middleUnitId=${middleUnitId}&`;
        });

        payload.studyItemTypes.forEach((studyItemType) => {
            url += `studyItemType=${studyItemType}&`;
        });

        payload.timeDifficulties.forEach((timeDifficulty) => {
            url += `timeDifficulty=${timeDifficulty}&`;
        });

        const response = await this.httpAdaptor.get<GetPlannedCurriculumsResponseDto>(
            url,
            payload.signal,
        );

        return response;
    };

    /**
     * @description 중단원 정보를 가져옵니다.
     */
    public getMiddleUnits = async () => {
        const response = await this.httpAdaptor.get<GetMiddleUnitsResponseDto>(`/v1/middle-units`);

        return response;
    };
}
