import { container } from "tsyringe";

// domain
import { UserRepository } from "@/domain/user/repositories/repositories";
import { ChallengeTemplateRepository } from "@/domain/challenge-template/repositories/repositories";
import { ChallengeRepository } from "@/domain/challenge/repositories/repositories";
import { StudentChallengeRepository } from "@/domain/academy/student-challenge/repositories/repositories";
import { SubmittedProblemRepository } from "@/domain/user/student/submitted-problems/repositories/repositories";
import { StudentRepository } from "@/domain/user/student/repositories/repositories";
import { ProblemRepository } from "@/domain/problem/repositories/repositories";
import { AssignedCurriculumProblemRepository } from "@/domain/assigned-curriculum-problem/repositories/repository";
import { ConceptEnhancementRepository } from "@/domain/concept-enhancement/repositories/repository";
import { ClassRepository } from "@/domain/class/repositories/repository";
import { SchoolRepository } from "@/domain/school/repositories/repositories";
import { LocationRepository } from "@/domain/location/repositories/repositories";
import { CurriculumRepository } from "@/domain/curriculum/repositories/repositories";
import { AcademyRepository } from "@/domain/academy/repositories/repositorie";
import { AttendanceRepository } from "@/domain/attendance/repositories/repositories";
import { AcademyRegistrationRequestRepository } from "@/domain/academy-registration-request/repositories/repositories";
import { CommonAccountsRepository } from "@/domain/common/interface/common-accounts/repositories/repositories";

// infrastructure
import UserRepositoryImpl from "@/infrastructure/repositoriesImpl/user/UserRepositoryImpl";
import ChallengeTemplateRepositoryImpl from "@/infrastructure/repositoriesImpl/challenge-template/ChallengeTemplateRepositoryImpl";
import ChallengeRepositoryImpl from "@/infrastructure/repositoriesImpl/challenge/ChallengeRepositoryImpl";
import StudentChallengesRepositoryImpl from "@/infrastructure/repositoriesImpl/academy/student-challenges/StudentChallengesRepositoryImpl";
import SubmittedProblemsRepositoryImpl from "@/infrastructure/repositoriesImpl/user/student/submitted-problems/SubmittedProblemsRepositoryImpl";
import StudentRepositoryImpl from "@/infrastructure/repositoriesImpl/user/student/StudentRepositoryImpl";
import ProblemRepositoryImpl from "@/infrastructure/repositoriesImpl/problem/ProblemRepositoryImpl";
import AssignedCurriculumProblemRepositoryImpl from "@/infrastructure/repositoriesImpl/assigned-curriculum-problem/AssignedCurriculumProblemRepositoryImpl";
import ConceptEnhancementRepositoryImpl from "@/infrastructure/repositoriesImpl/concept-enhancement/ConceptEnhancementRepositoryImpl";
import ClassRepositoryImpl from "@/infrastructure/repositoriesImpl/class/ClassRepositoryImpl";
import SchoolRepositoryImpl from "@/infrastructure/repositoriesImpl/school/SchoolRepositoryImpl";
import LocationRepositoryImpl from "@/infrastructure/repositoriesImpl/location/LocationRepositoryImpl";
import CurriculumRepositoryImpl from "@/infrastructure/repositoriesImpl/curriculum/CurriculumRepositoryImpl";
import AcademyRepositoryImpl from "@/infrastructure/repositoriesImpl/academy/AcademyRepositoryImpl";
import AttendanceRepositoryImpl from "@/infrastructure/repositoriesImpl/attendance/AttendanceRepositoryImpl";
import AcademyRegistrationRequestRepositoryImpl from "@/infrastructure/repositoriesImpl/academy-registration-request/AcademyRegistrationRequestRepositoryImpl";
import CommonAccountsRepositoryImpl from "@/infrastructure/repositoriesImpl/common-accounts/CommonAccountsRepositoryImpl";

// common
import { AccessStorageImpl } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";
import { AccessStorage } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";

const repositoriesRegisterInit = () => {
    // common
    container.register<AccessStorage>("AccessStorage", {
        useClass: AccessStorageImpl,
    });

    // domain
    container.register<UserRepository>("UserRepository", {
        useClass: UserRepositoryImpl,
    });
    container.register<ChallengeTemplateRepository>("ChallengeTemplateRepository", {
        useClass: ChallengeTemplateRepositoryImpl,
    });
    container.register<ChallengeRepository>("ChallengeRepository", {
        useClass: ChallengeRepositoryImpl,
    });
    container.register<StudentChallengeRepository>("StudentChallengeRepository", {
        useClass: StudentChallengesRepositoryImpl,
    });
    container.register<SubmittedProblemRepository>("SubmittedProblemRepository", {
        useClass: SubmittedProblemsRepositoryImpl,
    });
    container.register<StudentRepository>("StudentRepository", {
        useClass: StudentRepositoryImpl,
    });
    container.register<ProblemRepository>("ProblemRepository", {
        useClass: ProblemRepositoryImpl,
    });
    container.register<AssignedCurriculumProblemRepository>("AssignedCurriculumProblemRepository", {
        useClass: AssignedCurriculumProblemRepositoryImpl,
    });
    container.register<ConceptEnhancementRepository>("ConceptEnhancementRepository", {
        useClass: ConceptEnhancementRepositoryImpl,
    });
    container.register<ClassRepository>("ClassRepository", {
        useClass: ClassRepositoryImpl,
    });
    container.register<SchoolRepository>("SchoolRepository", {
        useClass: SchoolRepositoryImpl,
    });
    container.register<LocationRepository>("LocationRepository", {
        useClass: LocationRepositoryImpl,
    });
    container.register<CurriculumRepository>("CurriculumRepository", {
        useClass: CurriculumRepositoryImpl,
    });
    container.register<AcademyRepository>("AcademyRepository", {
        useClass: AcademyRepositoryImpl,
    });
    container.register<AttendanceRepository>("AttendanceRepository", {
        useClass: AttendanceRepositoryImpl,
    });
    container.register<AcademyRegistrationRequestRepository>(
        "AcademyRegistrationRequestRepository",
        {
            useClass: AcademyRegistrationRequestRepositoryImpl,
        },
    );
    container.register<CommonAccountsRepository>("CommonAccountsRepository", {
        useClass: CommonAccountsRepositoryImpl,
    });
};

export default repositoriesRegisterInit;
