import { singleton, inject } from "tsyringe";

// domain
import type {
    CommonAccountsRepository,
    RequestPostUserLoginPayload,
} from "@/domain/common/interface/common-accounts/repositories/repositories";
import type { SignInResponseDto } from "@/domain/common/interface/common-accounts/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class CommonAccountsRepositoryImpl implements CommonAccountsRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /**
     * @description 유저 로그인
     * @param {RequestPostUserLoginPayload} payload
     */
    public postUserLogin = async (payload: RequestPostUserLoginPayload) => {
        const response = await this.server.post<SignInResponseDto>(`/v2/sign-in`, payload);

        return response;
    };

    /**
     * @description 유저 로그아웃
     */
    public postUserLogout = async () => {
        await this.server.post(`/v2/sign-out`);
    };
}
