import { defineStore } from "pinia";
import { useRoute } from "vue-router";
import type { IRefetchTriggerState, IRefetchTriggerActions } from "./interface";

const useRefetchTriggerStore = defineStore<
    "refetchTriggerStore",
    IRefetchTriggerState,
    {},
    IRefetchTriggerActions
>("refetchTriggerStore", {
    state: (): IRefetchTriggerState => ({
        navigateTrigger: 0,
    }),
    actions: {
        routerPush() {
            this.navigateTrigger++;
        },
        resetPath() {
            this.navigateTrigger = 0;
        },
    },
});

export default useRefetchTriggerStore;
