import { singleton, inject } from "tsyringe";

// domain
import {
    AttendanceRepository,
    RequestGetStudentAttendancesPayload,
} from "@/domain/attendance/repositories/repositories";
import type { GetAttendeesResponseDto } from "@/domain/attendance/dtos";
// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class AttendanceRepositoryImpl implements AttendanceRepository {
    constructor(@inject(HttpAdaptor) private readonly httpAdaptor: HttpAdaptor) {}

    public getStudentAttendances = async (payload: RequestGetStudentAttendancesPayload) => {
        let url = `/v1/students/${payload.studentId}/attendances?`;

        if (payload.size) {
            url += `size=${payload.size}`;
        } else {
            url += `size=40`;
        }

        if (payload.from) {
            url += `from=${payload.from}`;
        }

        if (payload.to) {
            url += `to=${payload.to}`;
        }

        if (payload.type) {
            payload.type.forEach((type) => {
                url += `type=${type}`;
            });
        }

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.httpAdaptor.get<GetAttendeesResponseDto>(url);

        return response;
    };
}
