import { injectable, inject } from "tsyringe";
import { RouteLocationNormalized } from "vue-router";

// adapter
import { ClientStateManager } from "@/adapter/common/stores/client/interface";

// ui
import type { NavigationBarProps } from "@/ui/shared/components/navigation/navigationBar/NavigationbarModel";

@injectable()
export class NavigationBar {
    private _props: NavigationBarProps;

    constructor(
        props: NavigationBarProps,
        @inject("ModalManager") private modalManager: ClientStateManager.ModalManager,
        @inject("RefetchTriggerManager")
        private refetchTriggerManager: ClientStateManager.RefetchTriggerManager,
    ) {
        this._props = props;
    }

    get props(): NavigationBarProps {
        return this._props;
    }

    popupModal = () => {
        switch (this._props.value) {
            case "로그아웃": {
                this.modalManager.dispatch("onmountModal", [
                    "confirm-logout",
                    {
                        title: "정말 로그아웃 하시겠습니까?",
                        subTitle: "다음에 또 봐요!",
                        layout: "AlertWithSub",
                    },
                ]);
                return;
            }
            case "좌석 설정": {
                this.modalManager.dispatch("onmountModal", [
                    "setting-academy-seat",
                    {
                        title: "좌석 설정",
                        layout: "ModalBaseTitle",
                    },
                ]);
                return;
            }
            default:
                return;
        }
    };

    /**
     * @description 만일 현재의 route.path 가 props.path 와 같다면, refetchTrigger 내 navigatePath 를 적용시킵니다.
     * 경로 비교 시 기본 경로만 비교합니다.
     */
    conditionallyApplyRefetchTrigger = (route: RouteLocationNormalized) => {
        const currentNavigatePath = this.props.path;
        const currentPath = route.path;

        // 경로의 기본 부분만 추출 (첫 번째 슬래시 이후부터 다음 슬래시나 끝까지)
        const baseNavigatePath = currentNavigatePath.split("/")[1] || "";
        const baseCurrentPath = currentPath.split("/")[1] || "";

        if (baseNavigatePath === baseCurrentPath) {
            this.refetchTriggerManager.dispatch("routerPush");
        } else {
            this.refetchTriggerManager.dispatch("resetPath");
        }
    };
}

@injectable()
export class NavigationBarFactory {
    constructor(
        @inject("ModalManager") private modalManager: ClientStateManager.ModalManager,
        @inject("RefetchTriggerManager")
        private refetchTriggerManager: ClientStateManager.RefetchTriggerManager,
    ) {}

    create(props: NavigationBarProps) {
        return new NavigationBar(props, this.modalManager, this.refetchTriggerManager);
    }
}
