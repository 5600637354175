import { singleton, inject } from "tsyringe";

// domain
import { SchoolRepository } from "@/domain/school/repositories/repositories";
import { GetSchoolResponseDto } from "@/domain/school/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http/index";

@singleton()
export default class SchoolRepositoryImpl implements SchoolRepository {
    constructor(
        @inject(HttpAdaptor)
        private readonly httpAdaptor: HttpAdaptor,
    ) {}

    public getSchools = async (keyword: string) => {
        const response = await this.httpAdaptor.get<GetSchoolResponseDto>(
            `/v1/schools?&keyword=${keyword}`,
        );

        return response;
    };
}
