import { container } from "tsyringe";
import { RouteLocationNormalized } from "vue-router";

// adapter
import { AdapterServerStateManager } from "@/adapter/common/stores/server/interface";

/**
 * 인증 상태를 확인하고 필요한 경우 리디렉션 경로를 반환하는 가드
 * @param to 이동할 라우트
 * @param from 현재 라우트
 * @returns 리디렉션 경로 또는 내비게이션 허용 여부(true)
 */
export const authGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const authManager = container.resolve<AdapterServerStateManager.AuthManager>("AuthManager");
    const accesToken = authManager.getState().userAccessToken;

    // 로그인되지 않은 경우
    if (!accesToken) {
        if (to.meta.requiresAuth) {
            return "/auth"; // 리디렉션 경로 반환
        }
    }
    // 이미 로그인된 경우
    else {
        if (to.name === "auth") {
            return "/student-setting"; // 리디렉션 경로 반환
        }
    }

    return true; // 내비게이션 허용
};
