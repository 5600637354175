import { container } from "tsyringe";
import { RouteLocationNormalized } from "vue-router";

// adapter
import { AdapterServerStateManager } from "@/adapter/common/stores/server/interface";

/**
 * 학생 관리 페이지로 이동 시 학생 상태를 리셋하는 가드
 * @param to 이동할 라우트
 * @param from 현재 라우트
 */
export const resetStudentStateGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
) => {
    if (from.name !== "student-setting" && to.name === "student-setting") {
        const studentManager =
            container.resolve<AdapterServerStateManager.StudentManager>("StudentManager");
        const candidateManager =
            container.resolve<AdapterServerStateManager.CandidateManager>("CandidateManager");

        studentManager.dispatch("resetState");
        candidateManager.dispatch("resetState");
    }
};
