import { singleton, inject } from "tsyringe";

// domain
import {
    AcademyRegistrationRequestRepository,
    RequestGetAcademyRegistrationRequestPayload,
    RequestPatchAcademyRegistrationRequestPayload,
} from "@/domain/academy-registration-request/repositories/repositories";
import type {
    GetAcademyRegistrationListResponseDto,
    AcademyRegistrationResponseDto,
} from "@/domain/academy-registration-request/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class AcademyRegistrationRequestRepositoryImpl
    implements AcademyRegistrationRequestRepository
{
    constructor(@inject(HttpAdaptor) private readonly httpAdaptor: HttpAdaptor) {}

    /** @description 승인대기자를 호출합니다. */
    public getAcademyRegistrationRequests = async (
        payload: RequestGetAcademyRegistrationRequestPayload,
    ) => {
        let url = `/v1/academy-registration-requests?academyId=${payload.academyId}&`;

        if (payload.size) {
            url += `size=${payload.size}`;
        } else {
            url += `size=8`;
        }

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.httpAdaptor.get<GetAcademyRegistrationListResponseDto>(url);

        return response;
    };

    /** @description 학생 승인요청에 대한 승인, 취소(거절) */
    public patchAcademyRegistrationRequest = async (
        payload: RequestPatchAcademyRegistrationRequestPayload,
    ) => {
        const response = await this.httpAdaptor.patch<AcademyRegistrationResponseDto>(
            `/v1/academy-registration-requests/${payload.academyRegistrationRequestId}`,
            payload.requestDto,
        );

        return response;
    };
}
