export enum Evaluation {
    Correct = 0,
    Wrong = 1,
}

export enum StudyItemType {
    /** 프리뷰 */
    Preview = 1,
    /** 기본학습 (복습테스트) */
    ReviewTest = 2,
    /** 기본학습 (랜덤테스트) */
    RandomTest = 3,
    /** 모의고사 */
    MockExam = 6,
    /** 복습하기 */
    Review = 7,

    /** 개념정리하기 */
    Concept = 8,
    /** 개념연습하기 */
    ConceptPractice = 9,
    /** 개념 다지기 */
    ConceptReinforcement = 10,
    /** 개념 테스트 */
    ConceptTest = 11,
    /** 개념 활용하기 */
    ConceptApplication = 12,
    /** 개념 점검하기 */
    ConceptReview = 13,

    /** 개념별 훈련하기 */
    ConceptBasedChecking = 14,
    /** 유형별 훈련하기 */
    TypeBasedChecking = 15,
    /** 문제해석하기 */
    ProblemInterpretation = 16,

    /** 개념 마무리 */
    ConceptWrapUp = 17,
    /** 중단원 마무리 1step */
    MiddleUnitWrapUpStep1 = 18,
    /** 중단원 마무리 2step */
    MiddleUnitWrapUpStep2 = 19,

    /** 심화 1step */
    AdvancedStep1 = 20,
    /** 심화 2step */
    AdvancedStep2 = 21,
    /** 심화 3step */
    AdvancedStep3 = 22,

    /**  중단원테스트 1회*/
    MiddleUnitTest1 = 23,
    /**  중단원테스트 2회*/
    MiddleUnitTest2 = 25,
    /**  대단원테스트 1회*/
    BigUnitTest1 = 24,
    /**  대단원테스트 2회*/
    BigUnitTest2 = 26,

    /** 보충학습 */
    Additional = 27,
    /** 테스트 워밍업*/
    TestWarmingUp = 28,

    /** 특강 수업 */
    Special = 29,
}

export enum AchievementRank {
    S = 0,
    A = 1,
    B = 2,
    C = 3,
    F = 4,
}

export enum FeedbackStatus {
    Completed = 0,
    NotCompleted = 1,
}
