import { singleton, inject } from "tsyringe";

import HttpAdaptor from "@/infrastructure/libs/http";
import type {
    ClassRepository,
    RequestPostAcademyClassPayload,
    RequestPatchAcademyClassPayload,
    RequestPutAcademyClassStudentsPayload,
    RequestDeleteAcademyClassPayload,
} from "@/domain/class/repositories/repository";
import type { GetAcademyClassesResponseDto, ClassDto } from "@/domain/class/dtos";

@singleton()
export default class ClassRepositoryImpl implements ClassRepository {
    constructor(
        @inject(HttpAdaptor)
        private readonly httpAdaptor: HttpAdaptor,
    ) {}

    public getAcademyClasses = async (academyId: string): Promise<GetAcademyClassesResponseDto> => {
        const response = await this.httpAdaptor.get<GetAcademyClassesResponseDto>(
            `/v1/academies/${academyId}/classes?include=studentCount`,
        );

        return response;
    };

    /**
     * @description 학원 내 새로운 반을 생성합니다.
     * @param requestDto 반 이름, 선생님 배열(담임선생님 + 부담임선생님)
     *  */
    public postAcademyClass = async (payload: RequestPostAcademyClassPayload) => {
        const response = await this.httpAdaptor.post<ClassDto>(
            `/v1/academies/${payload.academyId}/classes`,
            payload.requestDto,
        );

        return response;
    };

    /** @description 학급을 수정합니다. (학생부분은 따로) */
    public patchAcademyClass = async (payload: RequestPatchAcademyClassPayload) => {
        const response = await this.httpAdaptor.patch<ClassDto>(
            `/v1/academies/${payload.academyId}/classes/${payload.classId}`,
            payload.requestDto,
        );

        return response;
    };

    /** @description 학급 내 학생들의 정보를 put */
    public putAcademyClassStudents = async (payload: RequestPutAcademyClassStudentsPayload) => {
        const response = await this.httpAdaptor.put<ClassDto>(
            `/v1/academies/${payload.academyId}/classes/${payload.classId}/students`,
            payload.requestDto,
        );

        return response;
    };

    /** @description 학급을 삭제합니다. */
    public deleteAcademyClass = async (payload: RequestDeleteAcademyClassPayload) => {
        await this.httpAdaptor.delete(
            `/v1/academies/${payload.academyId}/classes/${payload.classId}`,
        );
    };
}
