import { singleton, inject } from "tsyringe";

import type {
    UserRepository,
    RequestPatchUserEnhancementSetting,
    RequestGetExpectedAttendeesPayload,
    RequestGetTeachersPayload,
} from "@/domain/user/repositories/repositories";
import {
    GetTeachersResponseDto,
    GetExpectedAttendeesResponseDto,
    TeacherDto,
} from "@/domain/user/dtos";

import HttpClient from "@/infrastructure/libs/http";

@singleton()
export default class UserRepositoryImpl implements UserRepository {
    constructor(
        @inject(HttpClient)
        private readonly httpClient: HttpClient,
    ) {}

    /**
     *  @description
     *  등원학생 정보를 가져옵니다.
     */
    public getExpectedAttendees = async (payload: RequestGetExpectedAttendeesPayload) => {
        let url = `/v1/academies/${payload.academyId}/expected-attendees?include=timetable&`;

        if (payload.date) {
            url += `date=${payload.date}`;
        } else {
            url += `date=${new Date()}`;
        }

        if (payload.size) {
            url += `size=${payload.size}`;
        } else {
            url += `size=8`;
        }

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.httpClient.get<GetExpectedAttendeesResponseDto>(url);

        return response;
    };

    /**
     *  @description 선생님 정보를 가져옵니다.
     */
    public getTeacher = async (teacherId: string) => {
        const response = await this.httpClient.get<TeacherDto>(`/v1/teachers/${teacherId}`);

        return response;
    };

    /**
     *  @description 선생님 정보를 가져옵니다.
     */
    public getTeachers = async (payload: RequestGetTeachersPayload) => {
        let url = `/v1/academies/${payload.academyId}/teachers?`;

        if (payload.size) {
            url += `size=${payload.size}`;
        } else {
            url += `size=8`;
        }

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.httpClient.get<GetTeachersResponseDto>(url);

        return response;
    };

    public patchUserConceptEnhancementSetting = async (
        userId: string,
        payload: RequestPatchUserEnhancementSetting,
    ) => {
        const url = `/v1/users/${userId}`;

        await this.httpClient.patch(url, payload);
    };
}
