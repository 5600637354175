import { createRouter, createWebHistory } from "vue-router";

// guards
import { authGuard, resetStudentStateGuard } from "./guards";

/** layout */
import AuthLayout from "@/ui/shared/layouts/AuthLayout.vue";
import DefaultLayout from "@/ui/shared/layouts/DefaultLayout.vue";
import NotFoundLayout from "@/ui/shared/layouts/NotFoundLayout.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            redirect: "/auth",
        },
        {
            path: "/auth",
            name: "auth",
            component: () => import("@/ui/views/auth/IndexPage.vue"),
            meta: {
                requiresAuth: false,
                layout: AuthLayout,
            },
        },
        {
            path: "/student-setting/:id?",
            name: "student-setting",
            component: () => import("@/ui/views/student-setting/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/student-management",
            name: "student-management",
            component: () => import("@/ui/views/student-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/statistical-management",
            name: "statistical-management",
            component: () => import("@/ui/views/statistical-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/teacher-management",
            name: "teacher-management",
            component: () => import("@/ui/views/teacher-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/class-management",
            name: "class-management",
            component: () => import("@/ui/views/class-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/challenge-up",
            name: "challenge-up",
            component: () => import("@/ui/views/challenge-up/IndexPage.vue"),
            children: [
                {
                    path: "before-challenge-form",
                    name: "before-challenge-form",
                    component: () =>
                        import("@/ui/views/challenge-up/before-challenge-form/IndexPage.vue"),
                    meta: {
                        requiresAuth: true,
                        layout: DefaultLayout,
                    },
                },
                {
                    path: "after-challenge-form",
                    name: "after-challenge-form",
                    component: () =>
                        import("@/ui/views/challenge-up/after-challenge-form/IndexPage.vue"),
                    meta: {
                        requiresAuth: true,
                        layout: DefaultLayout,
                    },
                },
            ],
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/concept-enhancement",
            name: "concept-enhancement",
            component: () => import("@/ui/views/concept-enhancement/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/business-management",
            name: "business-management",
            component: () => import("@/ui/views/business-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/message-management",
            name: "message-management",
            component: () => import("@/ui/views/message-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/customer-center",
            name: "customer-center",
            component: () => import("@/ui/views/customer-center/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/setting",
            name: "setting",
            component: () => import("@/ui/views/setting/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/test",
            name: "test",
            component: () => import("@/ui/views/componentTest/IndexPage.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/:pathMatch(.*)*",
            name: "NotFound",
            component: () => import("@/ui/views/404.vue"),
            meta: {
                requiresAuth: false,
                layout: NotFoundLayout,
            },
        },
    ],
});

/** router가 작동하기 전에 인증 및 상태를 관리합니다 */
router.beforeEach((to, from, next) => {
    // 1. 인증 확인
    const authResult = authGuard(to, from);

    // 리디렉션이 필요한 경우
    if (typeof authResult === "string") {
        next(authResult);
        return;
    }

    // 2. 학생 상태 리셋 처리
    resetStudentStateGuard(to, from);

    // 3. 내비게이션 허용
    next();
});

export default router;
