import { defineStore } from "pinia";

import type {
    IFormerStudentManagerState,
    IFormerStudentDto,
    IFormerStudentManagerActions,
} from "./interface";

const useFormerStudentStore = defineStore<
    "formerStudentManager",
    IFormerStudentManagerState,
    {},
    IFormerStudentManagerActions
>("formerStudentManager", {
    state: (): IFormerStudentManagerState => ({
        academyFormerStudentDto: null,
        academyFormerStudentsDto: null,
    }),
    actions: {
        setAcademyFormerStudentDto(responseDto: IFormerStudentDto) {
            this.academyFormerStudentDto = responseDto;
        },
        setAcademyFormerStudentsDto(responseDto: IFormerStudentDto[]) {
            this.academyFormerStudentsDto = responseDto;
        },
        resetState() {
            this.academyFormerStudentDto = null;
            this.academyFormerStudentsDto = null;
        },
    },
});

export default useFormerStudentStore;
