import { container } from "tsyringe";

// impl
import StoreManagerImpl from "@/infrastructure/libs/state-management";

// store
import useChallengesClientStore from "@/infrastructure/stores/client/challenge-up";
import useChallengeSearchStudentStore from "@/infrastructure/stores/client/challenge-search-student";
import useRefetchTriggerStore from "@/infrastructure/stores/client/refetch-trigger";

// type
import { ChallengesClientState } from "@/adapter/common/stores/client/challeng-up/interface";
import { ChallengeSearchStudentState } from "@/adapter/common/stores/client/challenge-search-student/interface";
import { ClientStateManager } from "@/infrastructure/stores/client/interface";
import { RefetchTriggerState } from "@/adapter/common/stores/client/refetch-trigger/interface";

const stateRegisterInit = () => {
    container.register<ClientStateManager.ChallengesClient>("ChallengeUpManager", {
        useValue: new StoreManagerImpl<ChallengesClientState>(useChallengesClientStore()),
    });
    container.register<ClientStateManager.ChallengeSearchStudent>("ChallengeSearchStudentManager", {
        useValue: new StoreManagerImpl<ChallengeSearchStudentState>(
            useChallengeSearchStudentStore(),
        ),
    });
    container.register<ClientStateManager.RefetchTrigger>("RefetchTriggerManager", {
        useValue: new StoreManagerImpl<RefetchTriggerState>(useRefetchTriggerStore()),
    });
};

export default stateRegisterInit;
