import { singleton } from "tsyringe";
import {
    formatISO,
    parseISO,
    format,
    addWeeks,
    subWeeks,
    differenceInDays,
    differenceInWeeks,
    set,
    startOfDay as fnStartOfDay,
} from "date-fns";
import { TZDate } from "@date-fns/tz";

import {
    IDateAdapter,
    ParseISOAdapterOptions,
    FormatISOAdapterOptions,
    FormatDateAdapterOptions,
} from "./interface";

@singleton()
export default class DateFns implements IDateAdapter {
    constructor() {}

    public parseISO = (date: string, options?: ParseISOAdapterOptions): Date => {
        return parseISO(date, options?.fn);
    };

    public formatISO = (date: Date, options?: FormatISOAdapterOptions): string => {
        return formatISO(date, options?.fn);
    };

    public parseZoned = (date: Date, timeZone: string): Date => {
        return new TZDate(date, timeZone);
    };

    public formatZoned = (
        date: Date,
        timeZone: string,
        formatStr?: string,
        options?: FormatDateAdapterOptions,
    ): string => {
        const dateWithTimeZone = new TZDate(date, timeZone);

        return format(dateWithTimeZone, formatStr ?? "yyyy-MM-dd HH:mm:ss", options?.fn);
    };

    public addWeeks = (date: Date, weeks: number): Date => {
        return addWeeks(date, weeks);
    };

    public subWeeks = (date: Date, weeks: number): Date => {
        return subWeeks(date, weeks);
    };

    public differenceInDays = (dateLeft: Date, dateRight: Date): number => {
        return differenceInDays(dateLeft, dateRight);
    };

    public differenceInWeeks = (dateLeft: Date, dateRight: Date): number => {
        return differenceInWeeks(dateLeft, dateRight);
    };

    public setHours = (
        date: Date,
        hours: number,
        minutes?: number,
        seconds?: number,
        milliseconds?: number,
    ): Date => {
        return set(date, { hours, minutes, seconds, milliseconds });
    };

    public formatQueryDate = (date: Date): string => {
        const setTimezone = new TZDate(date, "Asia/Seoul");
        const formattedISODate = formatISO(setTimezone);

        return encodeURIComponent(formattedISODate);
    };

    /**
     * 날짜의 시간을 00:00:00으로 설정합니다.
     *
     * @param date 기준 날짜
     * @param timeZone 시간대
     * @returns 시간이 00:00:00으로 설정된 날짜
     */
    public startOfDay = (date: Date, timeZone: string): Date => {
        // 먼저 지정된 시간대로 날짜 변환
        const zonedDate = new TZDate(date, timeZone);

        // 해당 날짜의 시작 시간(00:00:00)으로 설정
        const startOfDayDate = fnStartOfDay(zonedDate);

        // 시간대 정보를 유지한 날짜 반환
        return new TZDate(startOfDayDate, timeZone);
    };

    /**
     * 두 날짜 사이의 일수 차이를 계산합니다. 시간 정보는 무시합니다.
     *
     * @param dateLeft 첫 번째 날짜
     * @param dateRight 두 번째 날짜
     * @param timeZone 시간대
     * @returns 일수 차이 (날짜만 고려)
     */
    public differenceInDaysIgnoringTime = (
        dateLeft: Date,
        dateRight: Date,
        timeZone: string,
    ): number => {
        // 각 날짜의 시작 시간(00:00:00)으로 설정
        const startOfDayLeft = this.startOfDay(dateLeft, timeZone);
        const startOfDayRight = this.startOfDay(dateRight, timeZone);

        // 밀리초 단위로 차이 계산
        const diffInMilliseconds = startOfDayLeft.getTime() - startOfDayRight.getTime();

        // 일 단위로 변환 (1일 = 24시간 = 86400000 밀리초)
        return Math.floor(diffInMilliseconds / 86400000);
    };
}
