<script setup lang="ts">
import { container } from "tsyringe";
import { defineAsyncComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { NavigationBarFactory } from "@/ui/shared/components/navigation/navigationBar/NavigationBarHandler";
import type {
    NavigationBarProps,
    NavigationBarEmits,
} from "@/ui/shared/components/navigation/navigationBar/NavigationbarModel";

const navigationBarFactory = container.resolve(NavigationBarFactory);
const route = useRoute();

const props = defineProps<NavigationBarProps>();
const emits = defineEmits<NavigationBarEmits>();
const handler = navigationBarFactory.create(props);

/** props 내 icon 에 따라 renderIcon 결정합니다. */
const renderIconComponent = computed(() => {
    return defineAsyncComponent(() => import(`../../base/svgs/${props.icon}.vue`));
});

/** 선택 된 메뉴에 관한 background color  */
const backgroundColorCustomClass = computed(() => {
    return {
        "bg-secondary-red": props.selectedIndex === props.index,
        "bg-white": props.selectedIndex !== props.index,
    };
});

/** 선택된 메뉴에 관한 text color  */
const textColorCustomClass = computed(() => {
    return {
        "text-primary-red": props.selectedIndex === props.index,
        "text-primary-gray": props.selectedIndex !== props.index,
    };
});

/** 선택 index 를 상위 컴포넌트에 전달 */
const emitSelectedMenuIndex = () => {
    handler.conditionallyApplyRefetchTrigger(route);
    emits("emit-selected-index", props.index);
};

/** 특정 섹션에 한하여 모달창을 오픈합니다. */
const openModal = () => {
    handler.popupModal();
};
</script>

<template>
    <router-link v-if="path" :to="path" @click="emitSelectedMenuIndex">
        <div
            class="flex justify-start items-center gap-[0.8rem] w-full py-[1.2rem] px-[0.8rem] rounded-[1.2rem] hover:bg-custom-hover-red cursor-pointer"
            :class="backgroundColorCustomClass"
        >
            <div class="w-[3.2rem] h-[3.2rem] flex justify-center items-center">
                <component
                    :is="renderIconComponent"
                    :color="selectedIndex === index ? '#FF6971' : '#85898A'"
                />
            </div>
            <p
                class="font-semibold text-md font-pretended whitespace-nowrap"
                :class="textColorCustomClass"
            >
                {{ value }}
            </p>
        </div>
    </router-link>
    <div
        v-else
        class="flex justify-start items-center gap-[0.8rem] w-full py-[1.2rem] px-[0.8rem] rounded-[1.2rem] hover:bg-custom-hover-red cursor-pointer"
        :class="backgroundColorCustomClass"
        @click="openModal"
    >
        <div class="w-[3.2rem] h-[3.2rem] flex justify-center items-center">
            <component
                :is="renderIconComponent"
                :color="selectedIndex === index ? '#FF6971' : '#85898A'"
            />
        </div>
        <p
            class="font-semibold text-md font-pretended whitespace-nowrap"
            :class="textColorCustomClass"
        >
            {{ value }}
        </p>
    </div>
</template>
