import { singleton, inject } from "tsyringe";

// domain
import { LocationRepository } from "@/domain/location/repositories/repositories";
import { GetLocationsResponseDto } from "@/domain/location/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http/index";

@singleton()
export default class LocationRepositoryImpl implements LocationRepository {
    constructor(
        @inject(HttpAdaptor)
        private readonly httpAdaptor: HttpAdaptor,
    ) {}

    public getLocations = async () => {
        const response = await this.httpAdaptor.get<GetLocationsResponseDto>(`/v1/locations`);

        return response;
    };
}
